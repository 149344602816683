import _isNull from 'lodash/isNull'

export const checkArgs = args => {
  Object.keys(args).forEach(arg => {
    if (args[arg] === undefined) throw new Error(`No ${arg} found!`)
  })
}

export const getQueryString = obj => {
  const entries = Object.entries(obj).filter(([key, value]) => value)

  if (entries.length > 0) {
    const params = new URLSearchParams()
    entries.forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })
    return `?${params.toString()}`
  }

  return ''
}

export const numberReducer = (acc, id) => {
  if (Array.isArray(acc)) {
    return !isNaN(id) ? [...acc, Number(id)] : acc
  }

  const arr = []

  if (!isNaN(acc)) {
    arr.push(Number(acc))
  }

  if (!isNaN(id)) {
    arr.push(Number(id))
  }

  return arr
}

export const strinc = (str, term) => {
  if (!isNaN(str)) return str === Number(term)
  str = _isNull(str) ? '' : str
  return str.toLowerCase().includes(term.toLowerCase())
}
