import {
  createContext,
  useCallback,
  useEffect,
  useContext,
  useState
} from 'react'
import { generateID } from 'react-querybuilder'
import { errorHandler } from '../utils/errors'
import { getSiteSettings } from '../utils/site'

const { iconUrl } = getSiteSettings()

const timers = new Map()

const NotificationsContext = createContext()

export let addNotifications = () => {}
export let removeNotifications = () => {}

export function NotificationsProvider({ children }) {
  const [notifications, setNotifications] = useState([])

  const removeTimer = useCallback((id) => timers.delete(id.toString()), [])

  const removeNotification = useCallback(
    (id) =>
      setNotifications((prevState) => {
        if (id) {
          removeTimer(id)
          prevState.find((n) => n.id === id.toString())?.system?.close()
          return prevState.filter((n) => n.id !== id.toString())
        }

        timers.clear()
        prevState.forEach((n) => n.system?.close())
        return []
      }),
    [removeTimer]
  )

  const addNotification = useCallback(
    (notification = {}) => {
      notification.id = notification.id?.toString?.() ?? generateID()

      setNotifications((prevState) => {
        let i = prevState.findIndex((n) => n.id === notification.id)

        if (i === -1) {
          i = prevState.findIndex(
            (n) =>
              n.body === notification.body && n.title === notification.title
          )
        }

        if (i > -1) {
          const nextState = [...prevState]
          nextState[i] = notification
          return nextState
        }

        if (window.Notification?.permission === 'granted') {
          import('html-to-text').then(
            ({ default: { htmlToText } }) => {
              notification.system = new Notification(notification.title, {
                body: htmlToText(notification.message),
                icon: iconUrl
              })

              notification.system.addEventListener('close', () =>
                removeNotification(notification.id)
              )

              notification.system.addEventListener('click', () =>
                window.focus()
              )
            },
            (error) => errorHandler(error)
          )
        }

        if (
          !notification.isPermanent &&
          !/(danger|warning)/.test(notification.type)
        ) {
          timers.set(
            notification.id,
            setTimeout(() => {
              if (timers.has(notification.id)) {
                removeNotification(notification.id)
                timers.delete(notification.id)
              }
            }, 5000)
          )
        }

        return [...prevState, notification]
      })

      return notification.id
    },
    [removeNotification]
  )

  useEffect(() => {
    addNotifications = (n) => n.map(addNotification)
    removeNotifications = (n) => n.map(removeNotification)
    return () => {
      addNotifications = undefined
      removeNotifications = undefined
    }
  }, [addNotification, removeNotification])

  return (
    <NotificationsContext.Provider
      value={{
        addNotification,
        notifications,
        removeNotification,
        removeTimer
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default function useNotifications() {
  return useContext(NotificationsContext)
}
