import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { generateID } from 'react-querybuilder'

const ModalsContext = createContext()

export let addModals = () => {}
export let removeModals = () => {}

export function ModalsProvider({ children }) {
  const [modals, setModals] = useState([])

  const removeModal = useCallback(
    (id) =>
      setModals((prevState) =>
        id ? prevState.filter((m) => m.id !== id) : []
      ),
    []
  )

  const addModal = useCallback((modal = {}) => {
    modal.id = modal.id?.toString?.() ?? generateID()

    setModals((prevState) => {
      const nextState = [...prevState]
      const i = nextState.findIndex((n) => n.id === modal.id)

      if (i > -1) {
        nextState[i] = modal
        return nextState
      }

      nextState.push({ actions: [], ...modal })
      return nextState
    })

    return modal.id
  }, [])

  useEffect(() => {
    addModals = (n) => n.map(addModal)
    removeModals = (n) => n.map(removeModal)
    return () => {
      addModals = undefined
      removeModals = undefined
    }
  }, [addModal, removeModal])

  return (
    <ModalsContext.Provider
      value={{
        addModal,
        modals,
        removeModal
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}

export default function useModals() {
  return useContext(ModalsContext)
}
