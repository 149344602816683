import PropTypes from 'prop-types'
import React from 'react'
import { getSiteSettings } from '../utils/site'

const Logo = ({ inverted = false, short = false, ...props }) => {
  const settings = getSiteSettings()

  if (settings.logoUrl) {
    return (
      <div className='m-n1' {...props}>
        <img
          alt='Logo'
          className='p-1'
          src={inverted ? settings.logoUrlDark : settings.logoUrl}
          style={{ height: 'calc(30px + 0.5rem)', width: 'auto' }}
        />
      </div>
    )
  }

  return (
    <div {...props}>
      <span
        className={`px-2 py-1 ${
          inverted ? 'bg-white text-primary' : 'bg-primary text-white'
        }`}
        style={{ lineHeight: '1.5em' }}
      >
        Beyond
      </span>
      <span
        className={`${inverted ? 'text-white' : 'text-dark'} ${
          short ? 'p-1' : 'ml-2'
        }`}
      >
        R{short ? '' : 'elationship '}M{!short && 'arketing'}
      </span>
    </div>
  )
}

Logo.propTypes = {
  inverted: PropTypes.bool,
  short: PropTypes.bool
}

export default Logo
