const requirements = (type, t = str => str) => {
  const requirements = {
    campaigns: [
      { key: 'ctype', label: t('misc.type') },
      { key: 'domain_id', label: t('campaigns.domain') },
      { key: 'esp_id' },
      { key: 'fromadr', label: t('campaigns.from') },
      { key: 'list_id', label: t('nav.segments') },
      { key: 'mailclass_id', label: t('campaigns.mailclass') },
      { key: 'message_id', label: t('templates.template') },
      { key: 'name', label: t('campaigns.title') },
      { key: 'reply_to', label: t('campaigns.reply_to') },
      { key: 'startdate', label: t('campaigns.sendout_time') },
      { key: 'throttle', label: t('campaigns.sending_speed') },
      { key: 'throttle_unit' },
      { key: 'use_domain_pool' },
      { key: 'valid' }
    ],
    campaignsextesp: [
      { key: 'ctype', label: t('misc.type') },
      { key: 'domain_id', label: t('campaigns.domain') },
      { key: 'esp_id' },
      { key: 'list_id', label: t('nav.segments') },
      { key: 'message_id', label: t('templates.template') },
      { key: 'name', label: t('campaigns.title') },
      { key: 'sendfrom_id', label: t('campaigns.from') },
      { key: 'startdate', label: t('campaigns.sendout_time') },
      { key: 'throttle', label: t('campaigns.sending_speed') },
      { key: 'throttle_unit' },
      { key: 'use_domain_pool' },
      { key: 'valid' }
    ],
    clients: [
      { key: 'client_name', label: t('misc.name') },
      { key: 'alias', label: t('misc.alias') },
      { key: 'app_host_id', label: t('services.apphosts') }
    ],
    clientsdomain: [
      { key: 'client_name', label: t('misc.name') },
      { key: 'alias', label: t('misc.alias') },
      { key: 'app_host_id', label: t('services.apphosts') },
      { key: 'domain', label: t('services.domains') },
      {
        key: 'emma_domain_settings.available_at_member_table_id',
        label: t('settings.restricted_to_datasources')
      },
      {
        key: 'emma_domain_settings.is_default_for_member_table_id',
        label: t('settings.default_for_datasources')
      },
      { key: 'mta_settings.dkim.selector', label: 'DKIM Selector' },
      { key: 'mta_settings.relay.host_id', label: t('settings.relay_host') },
      { key: 'mta_settings.domain.name', label: t('services.domains') }
    ],
    domainsetup: [
      {
        $or: [
          { key: 'apphostId', label: t('settings.relay_host') },
          { key: 'copyFrom', label: t('settings.dkim_relay_copyfrom') }
        ]
      },
      { key: 'clientId', label: t('services.clients') },
      {
        $or: [
          { key: 'dkimSelector', label: 'DKIM Selector' },
          { key: 'copyFrom', label: t('settings.dkim_relay_copyfrom') }
        ]
      },
      { key: 'domains', label: t('settings.domain_name') },
      { key: 'index_redirect', label: t('settings.index_redirect') }
    ],
    domainsetupplain: [
      { key: 'domains', label: t('settings.domain_name') },
      { key: 'index_redirect', label: t('settings.index_redirect') }
    ],
    ipallocation: [
      { key: 'client_id', label: t('services.clients') },
      { key: 'app_host_id', label: t('services.apphosts') },
      { key: 'ip_id', label: t('services.apphostips') }
    ],
    ipcicampaigns: [
      { key: 'imap_login_id', label: t('services.testmailboxes') },
      {
        $or: [
          { key: 'notify_email', label: t('misc.email') },
          { key: 'notify_sms', label: 'SMS' }
        ]
      }
    ],
    messages: [
      { key: 'headline_de', label: `${t('messages.headline')} (de)` },
      { key: 'headline_en', label: `${t('messages.headline')} (en)` },
      { key: 'headline_es', label: `${t('messages.headline')} (es)` },
      { key: 'message_de', label: `${t('messages.message')} (de)` },
      { key: 'message_en', label: `${t('messages.message')} (en)` },
      { key: 'message_es', label: `${t('messages.message')} (es)` },
      { key: 'start_date', label: t('messages.start_date') },
      { key: 'end_date', label: t('messages.end_date') },
      { key: 'modal', label: `${t('misc.notification')}/Modal` },
      {
        $or: [
          { key: 'modal_size', label: t('messages.modal_size') },
          { key: 'type', label: t('misc.type') }
        ]
      }
    ],
    recurringcampaigns: [
      { key: 'auto_day', label: t('campaigns.auto_day') },
      { key: 'auto_frequency', label: t('campaigns.auto_frequency') },
      { key: 'auto_status', label: t('misc.status') },
      { key: 'ctype', label: t('misc.type') },
      { key: 'domain_id', label: t('campaigns.domain') },
      { key: 'esp_id' },
      { key: 'fromadr', label: t('campaigns.from') },
      { key: 'list_id', label: t('nav.segments') },
      { key: 'mailclass_id', label: t('campaigns.mailclass') },
      { key: 'message_id', label: t('templates.template') },
      { key: 'name', label: t('campaigns.title') },
      { key: 'reply_to', label: t('campaigns.reply_to') },
      { key: 'startdate', label: t('messages.start_date') },
      { key: 'throttle', label: t('campaigns.sending_speed') },
      { key: 'throttle_unit' },
      { key: 'use_domain_pool' }
    ],
    recurringcampaignsdaily: [
      { key: 'auto_frequency', label: t('campaigns.auto_frequency') },
      { key: 'auto_status', label: t('misc.status') },
      { key: 'ctype', label: t('misc.type') },
      { key: 'domain_id', label: t('campaigns.domain') },
      { key: 'esp_id' },
      { key: 'fromadr', label: t('campaigns.from') },
      { key: 'list_id', label: t('nav.segments') },
      { key: 'mailclass_id', label: t('campaigns.mailclass') },
      { key: 'message_id', label: t('templates.template') },
      { key: 'name', label: t('campaigns.title') },
      { key: 'reply_to', label: t('campaigns.reply_to') },
      { key: 'startdate', label: t('messages.start_date') },
      { key: 'throttle', label: t('campaigns.sending_speed') },
      { key: 'throttle_unit' },
      { key: 'use_domain_pool' }
    ],
    templates: [
      {
        $or: [
          { key: 'body_long', label: 'HTML' },
          { key: 'body_text', label: 'Text' }
        ]
      },
      { key: 'depuis', label: t('templates.sender') },
      { key: 'name', label: t('templates.title') },
      { key: 'subject', label: t('templates.subject') }
    ],
    testmailboxes: [
      { key: 'email', label: t('misc.email') },
      { key: 'imap_setting_id', label: t('services.imapsettings') },
      { key: 'ipci_check_type', label: t('misc.type') },
      { key: 'passwd', label: t('login.password') }
    ],
    transactionals: [
      { key: 'ctype', label: t('misc.type') },
      { key: 'domain_id', label: t('campaigns.domain') },
      { key: 'fromadr', label: t('campaigns.from') },
      { key: 'mailclass_id', label: t('campaigns.mailclass') },
      { key: 'message_id', label: t('templates.template') },
      { key: 'name', label: t('campaigns.title') },
      { key: 'reply_to', label: t('campaigns.reply_to') }
    ],
    users: [
      { key: 'email', label: t('misc.email') },
      { key: 'firstname', label: t('misc.forename') },
      { key: 'lastname', label: t('misc.surname') }
    ],
    'users-new': [
      { key: 'email', label: t('misc.email') },
      { key: 'firstname', label: t('misc.forename') },
      { key: 'lastname', label: t('misc.surname') },
      { key: 'password', label: t('users.password') },
      { key: 'role', label: t('users.role') },
      { key: 'user_name', label: t('login.username') }
    ],
    webforms: [
      { key: 'consent_html', label: t('subscribers.consent') },
      { key: 'doi_campaign_id', label: t('subscribers.doi_transactional') },
      { key: 'form_fields', label: t('misc.form') },
      { key: 'name', label: t('misc.name') }
    ]
  }
  return requirements[type]
}
export default requirements
