import React, { Component } from 'react'
import { Translation } from 'react-i18next'
import { Button } from 'reactstrap'
import Fieldset from './Fieldset'
import Logo from './Logo'

export default class ErrorBoundary extends Component {
  state = { error: null }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, info) {
    console.error(error, info)
  }

  render() {
    const { error } = this.state
    return error
      ? (
        <div className='error-boundary'>
          <div
            className='align-items-center bg-white d-flex shadow-sm'
            style={{ height: '48px' }}
          >
            <div className='d-none d-sm-block'>
              <Logo className='h5 m-0 pl-3' />
            </div>
            <div className='d-sm-none'>
              <Logo className='h5 m-0 pl-3' short />
            </div>
          </div>
          <div
            className='d-flex justify-content-center align-items-center p-4'
            style={{
              height: 'calc(100vh - 48px)'
            }}
          >
            <Translation>
              {t => (
                <div className='w-md-75 w-lg-50 m-4'>
                  <div className='bg-white p-4 shadow-sm'>
                    <h1 className='h2 mb-4'>{t('errors.generic_message')}</h1>
                    {error.message && (
                      <Fieldset title={t('errors.message')}>
                        <code
                          className='small text-muted'
                          style={{ wordBreak: 'break-all' }}
                        >
                          {error.message}
                        </code>
                      </Fieldset>
                    )}
                    <p className='mt-3'>{t('errors.notified')}</p>
                    <Button
                      color='primary'
                      onClick={() => window.location.assign('/')}
                    >
                      {t('errors.reload_app')}
                    </Button>
                  </div>
                </div>
              )}
            </Translation>
          </div>
        </div>
      )
      : (
        this.props.children
      )
  }
}
