import { getColor, getStyle } from '../../utils/colors'

export const getStyles = ({
  hasIconLeft,
  hasIconRight,
  isLoading,
  isSmall,
  placeholderColor,
  lineThrough
}) => ({
  control: (base, { isDisabled, isFocused }) => ({
    ...base,
    backgroundColor: isDisabled
      ? getColor('dark') || 'var(--light)'
      : getColor('darker') || base.backgroundColor,
    borderColor: isFocused
      ? getColor('grayer') || 'var(--primary-light)!important'
      : getColor('grayer') || 'var(--input-border)',
    borderWidth: '1px',
    borderBottomLeftRadius: hasIconLeft ? 0 : undefined,
    borderTopLeftRadius: hasIconLeft ? 0 : undefined,
    borderBottomRightRadius: hasIconRight ? 0 : undefined,
    borderTopRightRadius: hasIconRight ? 0 : undefined,
    boxShadow: isFocused
      ? getStyle('boxShadow') || '0 0 0 0.2rem var(--primary-25)'
      : base.boxShadow,
    color: 'var(--input-color)',
    cursor: 'text',
    fontSize: isSmall ? '.85rem' : undefined,
    minHeight: 'initial',
    outline: isFocused ? 0 : base.outline,
    textDecoration: lineThrough ? 'line-through' : undefined,
    '&:hover': {
      borderColor: 'var(--secondary-50)' || 'var(--secondary-25)'
    }
  }),
  dropdownIndicator: base => ({
    ...base,
    color: 'var(--secondary)'
  }),
  group: base => ({
    ...base,
    backgroundColor: getColor('darker') || base.backgroundColor
  }),
  groupHeading: base => ({
    ...base,
    backgroundColor: getColor('darker') || base.backgroundColor
  }),
  indicatorSeparator: () => null,
  indicatorsContainer: base => ({
    ...base,
    '&> div': {
      padding: 4
    }
  }),
  input: base => ({
    ...base,
    color: getColor('white') || 'var(--input-color)',
    paddingBottom: isSmall ? 0 : base.paddingBottom,
    paddingTop: isSmall ? 1 : base.paddingTop
  }),
  loadingMessage: base => ({
    ...base,
    backgroundColor: getColor('darker') || 'transparent',
    color: getColor('white') || 'var(--dark)',
    fontSize: 'smaller'
  }),
  menu: base => ({
    ...base,
    backgroundColor: getColor('darker') || 'var(--white)',
    borderColor: 'var(--input-border)',
    boxShadow: '0 0.5rem 1rem var(--dropdown-boxshadow) !important',
    marginTop: '0.5em',
    overflow: 'hidden',
    top: 'initial',
    zIndex: 7
  }),
  menuList: base => ({
    ...base,
    border: `1px solid ${getColor('grayer') || 'var(--dropdown-border)'}`,
    borderRadius: '4px',
    padding: 0
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: getColor('grayer') || 'var(--light)',
    borderRadius: '0.25rem',
    color: getColor('white') || base.color
  }),
  multiValueLabel: base => ({
    ...base,
    color: getColor('white') || base.color,
    fontSize: '15px',
    padding: '0.1em 0.4em'
  }),
  multiValueRemove: base => ({
    ...base,
    '&:hover': {
      color: 'white',
      cursor: 'pointer'
    }
  }),
  noOptionsMessage: base => ({
    ...base,
    backgroundColor: getColor('darker') || 'transparent',
    color: getColor('white') || 'var(--dark)',
    fontSize: 'smaller'
  }),
  option: (base, { isDisabled, isFocused }) => ({
    ...base,
    backgroundColor: isFocused
      ? getColor('grayer') || 'var(--light)'
      : getColor('darker') || 'transparent',
    color: getColor('white') || 'var(--dark)',
    fontSize: '0.85rem',
    opacity: isLoading || isDisabled ? 0.5 : 1.0,
    padding: '10px 20px'
  }),
  placeholder: base => ({
    ...base,
    color: placeholderColor || getColor('grayer') || 'var(--secondary)',
    whiteSpace: 'nowrap'
  }),
  singleValue: base => ({
    ...base,
    color: getColor('white') || 'var(--input-color)'
  })
})

export const theme = {
  borderRadius: 4,
  colors: {
    danger: 'var(--danger)',
    dangerLight: 'var(--danger-50)',
    neutral0: 'hsl(0, 0%, 100%)',
    neutral10: 'hsl(0, 0%, 90%)',
    neutral20: 'hsl(0, 0%, 80%)',
    neutral30: 'hsl(0, 0%, 70%)',
    neutral40: 'hsl(0, 0%, 60%)',
    neutral50: 'hsl(0, 0%, 50%)',
    neutral5: 'hsl(0, 0%, 95%)',
    neutral60: 'hsl(0, 0%, 40%)',
    neutral70: 'hsl(0, 0%, 30%)',
    neutral80: 'hsl(0, 0%, 20%)',
    neutral90: 'hsl(0, 0%, 10%)',
    primary25: 'var(--primary-25)',
    primary50: 'var(--primary-50)',
    primary75: 'var(--primary-75)',
    primary: 'var(--primary)'
  },
  spacing: {
    baseUnit: 4,
    controlHeight: 38,
    menuGutter: 8
  }
}
