import { generateID } from 'react-querybuilder'
import { finalize, map, switchMap } from 'rxjs'
import { decrLoading, incrLoading } from '../hooks/useUser'
import { errorHandler } from '../utils/errors'
import fromFetch from './from-fetch'

const baseUrl = 'https://expurgate-notifications.beyondrm.com'

let queued = []
let subscribers = []
let ws = { send: (m) => queued.push(m) }

const flushQueued = () => {
  queued.forEach((m) => ws.send(m))
  queued = []
}

const getHeaders = (hasBody) => {
  const headers = {
    'accept-encoding': 'br, gzip, deflate',
    accept: 'application/json'
  }

  if (hasBody) {
    headers['content-type'] = 'application/json'
  }

  if (localStorage.loginToken) {
    headers.authorization = `Bearer ${localStorage.loginToken}`
  }

  return headers
}

const messageHandler = (e) => {
  const { data, type } = JSON.parse(e.data)

  if (type === 'ERROR') {
    return errorHandler({ message: data })
  }

  subscribers.forEach((s) => {
    if (s.type === type) {
      s.callback?.(data)
    }
  })
}

const connectWebSocket = () => {
  ws = new WebSocket(
    `${baseUrl.replace(/^https/, 'wss')}/ws`,
    localStorage.loginToken
  )
  ws.onclose = () => setTimeout(connectWebSocket, 60000)
  ws.onmessage = messageHandler
  ws.onopen = flushQueued
}

const adapter = (endpoint = '/') => ({
  create$: (data = {}, options = {}) => {
    !options.ignoreLoadingState && incrLoading()

    const req = new Request(`${baseUrl}${endpoint}`, {
      body: JSON.stringify(data),
      headers: getHeaders(true),
      method: 'POST'
    })

    return fromFetch(req).pipe(
      switchMap((res) => res.text()),
      finalize(() => !options.ignoreLoadingState && decrLoading())
    )
  },

  find$: (options = {}) => {
    !options.ignoreLoadingState && incrLoading()

    const req = new Request(`${baseUrl}${endpoint}`, {
      headers: getHeaders()
    })

    return fromFetch(req).pipe(
      switchMap((res) => res.text()),
      map((body) => {
        if (!body.startsWith('[')) {
          throw new Error(body)
        }
        return JSON.parse(body)
      }),
      finalize(() => !options.ignoreLoadingState && decrLoading())
    )
  },

  findOne$: (id = '', options = {}) => {
    !options.ignoreLoadingState && incrLoading()

    const req = new Request(`${baseUrl}${endpoint}${id}`, {
      headers: getHeaders()
    })

    return fromFetch(req).pipe(
      switchMap((res) => res.text()),
      map((body) => {
        if (!body.startsWith('{')) {
          throw new Error(body)
        }
        return JSON.parse(body)
      }),
      finalize(() => !options.ignoreLoadingState && decrLoading())
    )
  },

  remove$: (id = '', options = {}) => {
    !options.ignoreLoadingState && incrLoading()

    const req = new Request(`${baseUrl}${endpoint}${id}`, {
      headers: getHeaders(),
      method: 'DELETE'
    })

    return fromFetch(req).pipe(
      switchMap((res) => res.text()),
      finalize(() => !options.ignoreLoadingState && decrLoading())
    )
  },

  subscribe: (subs = []) => {
    const _id = generateID()

    subscribers = [...subscribers, ...subs.map((s) => ({ ...s, _id }))]

    return {
      next: (type, data) => ws.send(JSON.stringify({ type, data })),
      unsubscribe: () => {
        subscribers = subscribers.filter((s) => s._id !== _id)
      }
    }
  }
})

setTimeout(connectWebSocket, 5000)

export default adapter
