import PropTypes from 'prop-types'
import React from 'react'

const Fieldset = ({ children, className, title, ...props }) => (
  <fieldset
    className={`bg-white px-3 pb-2 pt-${title ? '2' : '3'} rounded shadow-sm ${
      className ? className : ''
    }`}
    {...props}
  >
    {title && (
      <legend
        className='bg-white shadow-sm border-bottom-0 h5 mb-2 p-input py-1 w-auto'
        style={{
          borderTopLeftRadius: '0.25rem',
          borderTopRightRadius: '0.25rem'
        }}
      >
        {title}
      </legend>
    )}
    {children}
  </fieldset>
)

Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string
  ])
}

export default Fieldset
