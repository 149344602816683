import React from 'react'
import useModals from '../hooks/useModals'
import Modal from './Modal'
import Snow from './Snow'

const Modals = () => {
  const { modals } = useModals()
  return (
    <>
      {modals.map((modal) => (
        <Modal key={modal.id} {...modal} />
      ))}

      {modals.some((m) => m.hasSnow) && <Snow />}
    </>
  )
}

export default Modals
