export const getTestSubscriberTypes = (t = str => str) =>
  [
    { label: t('services.testsubscribers'), value: 'testaddr' },
    { label: t('subscribers.global_seed'), value: 'global_seed' },
    { label: t('subscribers.proof_copy'), value: 'beleg' },
    { label: t('subscribers.imap_user'), value: 'imapuser' }
  ].sort((a, b) => a.label.localeCompare(b.label))

export const partitionData = (parsedData, numCols) => {
  numCols = numCols || parsedData[0].length

  const dataCsv = []
  const dataIncomplete = []
  for (let i = 0; i < parsedData.length; i++) {
    parsedData[i].length === numCols
      ? dataCsv.push(parsedData[i])
      : dataIncomplete.push(parsedData[i])
  }

  return { dataCsv, dataIncomplete }
}
