import merge from 'lodash/merge'
import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState
} from 'react'
import { forkJoin, map, of, switchMap } from 'rxjs'
import { routes } from '../App'
import api from '../api'
import { broadcastChannel, joinChannel, leaveChannel } from '../broadcast'

const initialUserState = {
  datasources: [],
  session: null,
  settings: null,
  theme: null,
  view_as_role: null
}

const findBaseRoute = (path, r) => {
  const basePath = path
    .split('/')
    .filter((p) => !r.test(p))
    .slice(0, 2)
    .join('/')

  return (
    routes.find((r) => r.path === `${basePath}/archive`) ||
    routes.find((r) => r.path === basePath)
  )?.path
}

const UserContext = createContext()

export let decrLoading = () => {}
export let getUserClient = () => {}
export let getUserDatasources = () => {}
export let getUserSettings = () => initialUserState
export let incrLoading = () => {}
export let updateUser = (updates) => {
  merge(initialUserState, updates)
}

export const handleLogout$ = () =>
  api.authentication.logout$().pipe(
    map((res) => {
      leaveChannel()
      updateUser(initialUserState)
      return res
    })
  )

export const restart = (broadcast = true) => {
  const r = /\/(edit|test)\/\d+/
  const path = window.location.pathname

  if (!r.test(path)) {
    broadcast && broadcastChannel({ type: 'RESTART' })
    return window.location.reload()
  }

  const parts = path.split('/').filter(Boolean)
  let baseRoute = findBaseRoute(path, r)

  while (!baseRoute && parts.length > 0) {
    parts.pop()
    baseRoute = findBaseRoute(`/${parts.join('/')}`, r)
  }

  broadcast && broadcastChannel({ type: 'RESTART' })
  window.location.assign(baseRoute || '/dashboard')
}

export function UserProvider({ children }) {
  const [loading, setLoading] = useState(0)
  const [openSidebar, setOpenSidebar] = useState(false)
  const [user, setUser] = useState(initialUserState)

  const toggleSidebar = useCallback(
    (fn = (prevState) => !prevState) => setOpenSidebar(fn),
    []
  )

  const getCustomSettings$ = useCallback(
    (key, id = user.session.sub.id, extraOpts) =>
      api.usersettings
        .find$({ limit: null, skip: null, ...extraOpts }, { id })
        .pipe(
          switchMap(({ data: [{ custom }] }) => {
            custom = !custom || Array.isArray(custom) ? {} : custom
            return of(key ? custom[key] : custom)
          })
        ),
    [user.session?.sub.id]
  )

  const handleLogin$ = useCallback(
    (username, password) =>
      api.authentication.login$(username, password).pipe(
        switchMap((session) => {
          joinChannel()
          return forkJoin([
            of(session),
            api.usersettings.find$(undefined, { id: session.sub.id }),
            api.datasources.findAll$()
          ])
        }),
        switchMap(
          ([
            session,
            {
              data: [settings]
            },
            { data: datasources }
          ]) =>
            forkJoin([
              of(session),
              of(settings),
              of(datasources),
              settings.client_id
                ? api.clients.findOne$(settings.client_id)
                : of({})
            ])
        ),
        map(([session, settings, datasources, client]) => {
          setUser((prevState) => ({
            ...prevState,
            client,
            datasources,
            session,
            settings
          }))

          return {
            client,
            datasources,
            session,
            settings
          }
        })
      ),
    []
  )

  const hasAdmin = useCallback(
    () =>
      (user.session?.sub.superadmin && !user.view_as_role) ||
      user.session?.sub.administrator ||
      user.settings.roles.includes('administrator'),
    [
      user.session?.sub.administrator,
      user.session?.sub.superadmin,
      user.settings?.roles,
      user.view_as_role
    ]
  )

  const hasSuperadmin = useCallback(
    () => user.session?.sub.superadmin && !user.view_as_role,
    [user.session?.sub.superadmin, user.view_as_role]
  )

  const hasPermissions = useCallback(
    (perms, needsAllPerms = true) => {
      if (!perms || !user.settings?.permissions || hasSuperadmin()) {
        return true
      }

      if (typeof perms === 'string') {
        perms = [perms]
      }

      const userPerms = Object.entries(
        user.view_as_role || user.settings?.permissions || {}
      ).flatMap(([endpoint, verbs]) =>
        verbs.map((verb) => `${endpoint}:${verb}`)
      )

      return needsAllPerms
        ? perms.every((p) => userPerms.includes(p))
        : perms.some((p) => userPerms.includes(p))
    },
    [hasSuperadmin, user.settings?.permissions, user.view_as_role]
  )

  const saveCustomSettings$ = useCallback(
    (newSettings = {}, id = user.session.sub.id, extraOpts) =>
      getCustomSettings$(undefined, id, extraOpts).pipe(
        switchMap((custom) => {
          const nextState = {
            ...custom,
            ...newSettings
          }

          setUser((prevState) => ({
            ...prevState,
            settings: {
              ...prevState.settings,
              custom: nextState
            }
          }))

          return api.usersettings.create$(
            { custom: nextState },
            { id },
            extraOpts
          )
        })
      ),
    [getCustomSettings$, user.session?.sub.id]
  )

  const saveSettings$ = useCallback(
    (newSettings, id = user.session?.sub.id, extraOpts) =>
      user.session
        ? api.usersettings.create$(newSettings, { id }, extraOpts).pipe(
            switchMap(() =>
              // create$() does not return any permissions, so we need a subsequent
              // find$()
              api.usersettings.find$({ limit: null, skip: null }, { id })
            ),
            map(({ data: [settings] }) => {
              setUser((prevState) => ({
                ...prevState,
                settings
              }))
              return settings
            })
          )
        : of(newSettings),
    [user.session]
  )

  const setClient = useCallback((client) =>
    setUser((prevState) => ({
      ...prevState,
      client
    }))
  )

  const setViewAsRole = useCallback(
    (role) =>
      setUser((prevState) => ({
        ...prevState,
        view_as_role: role
      })),
    []
  )

  useLayoutEffect(() => {
    decrLoading = () =>
      setLoading((prevState) => (prevState <= 0 ? 0 : --prevState))

    incrLoading = () => setLoading((prevState) => ++prevState)

    updateUser = (updates = {}) =>
      setUser((prevState) => ({
        ...prevState,
        ...updates
      }))

    return () => {
      decrLoading = () => {}
      incrLoading = () => {}
      updateUser = () => {}
    }
  }, [])

  useLayoutEffect(() => {
    getUserClient = () => user.client
    getUserDatasources = () => user.datasources
    getUserSettings = () => user.settings

    return () => {
      getUserClient = () => {}
      getUserDatasources = () => {}
      getUserSettings = () => {}
    }
  }, [user])

  return (
    <UserContext.Provider
      value={{
        getCustomSettings$,
        handleLogin$,
        hasAdmin,
        hasPermissions,
        hasSuperadmin,
        loading,
        openSidebar,
        saveCustomSettings$,
        saveSettings$,
        setClient,
        setViewAsRole,
        toggleSidebar,
        user
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default function useModals() {
  return useContext(UserContext)
}
