export const getFractionDigits = (num, max = 2) => {
  max = max > 10 ? 10 : max

  for (let digits = 0; digits <= max; digits++) {
    const p = 10 ** digits
    const remainder = (num * p) % 1

    if (
      remainder === 0 ||
      (p > 1 && remainder < num / p ** 2) ||
      digits >= max
    ) {
      return digits
    }
  }
}

export const getRandomNumber = (x, y, precision = 0) =>
  precision
    ? +(Math.random() * (y - x + 1) + x).toFixed(precision)
    : Math.floor(Math.random() * (y - x + 1) + x)
