import { finalize, map, switchMap } from 'rxjs/operators'
import { decrLoading, incrLoading } from '../hooks/useUser'
import { decodeHtmlEntities } from '../utils/data'
import fromFetch from './from-fetch'

const adapter = () => ({
  create$: (data = {}, options = {}) => {
    const sl = data.source ?? 'de'
    const tl = data.target ?? 'en'
    const q = encodeURI(decodeHtmlEntities(data.text)) ?? ''

    !options.ignoreLoadingState && incrLoading()

    return fromFetch(
      `https://translate.googleapis.com/translate_a/single?client=gtx&sl=${sl}&tl=${tl}&dt=t&q=${q}`
    ).pipe(
      switchMap((res) => res.json()),
      map((json) => json[0].map((p) => p[0]).join('')),
      finalize(() => !options.ignoreLoadingState && decrLoading())
    )
  }
})

export default adapter
