export const getApiHost = hostname => {
  if (!hostname && typeof window !== 'undefined') {
    hostname = window.location.hostname
  }

  if (sessionStorage.apiUrl) {
    return sessionStorage.apiUrl
  }

  switch (hostname) {
    case 'emma-dev.beyondrm.com':
    case 'emma.preview.mundpropaganda.net':
    case 'enimail.preview.mundpropaganda':
    case 'localhost':
      return 'api-dev.beyondrm.com'

    case 'emma-staging.beyondrm.com':
      return 'api-staging.beyondrm.com'

    case 'ems.enimail.de':
      return 'api.enimail.de'

    default:
      return import.meta.env.REACT_APP_API_HOST || 'api.beyondrm.com'
  }
}

export const getInstallType = (hostname = window.location.hostname) => {
  switch (hostname) {
    case 'emma-dev.beyondrm.com':
    case 'emma.preview.mundpropaganda.net':
    case 'enimail.preview.mundpropaganda.net':
    case 'localhost':
      return 'dev'

    default:
      return 'prod'
  }
}

export const getSiteSettings = (hostname = window.location.hostname) => {
  switch (hostname) {
    case 'ems-dev.enimail.de':
    case 'ems.enimail.de':
    case 'enimail.preview.mundpropaganda.net':
      return {
        appName: 'EMS',
        iconUrl:
          'https://www.enigami.de/wp-content/uploads/2017/08/loading-1.png',
        logoUrl: '/Enigami-Mail.webp',
        logoUrlDark: '/Enigami-Mail-w.webp',
        primaryColor: '#06a9e5',
        promoUrl: 'https://www.enigami.de/',
        title: 'ENIGAMI Mail'
      }

    default:
      return {
        appName: 'EMMA',
        iconUrl: '/favicon-32x32.png',
        primaryColor: '#ff6600',
        promoUrl: 'https://www.beyondrm.com',
        ticketsystemUrl: 'https://tickets.beyondrm.com/',
        title: 'Beyond Relationship Marketing'
      }
  }
}

export const siteSetup = () => {
  window.localStorage.setItem('initialPath', window.location.pathname)
  const settings = getSiteSettings()

  document.head.dataset.titlePrefix = settings.title

  const linkIcon = document.createElement('link')
  linkIcon.setAttribute('href', settings.iconUrl)
  linkIcon.setAttribute('rel', 'shortcut icon')
  linkIcon.setAttribute('type', 'image/x-icon')
  document.head.append(linkIcon)

  const metaTheme = document.createElement('meta')
  metaTheme.setAttribute('name', 'theme-color')
  metaTheme.setAttribute('content', settings.primaryColor)
  document.head.append(metaTheme)
}
