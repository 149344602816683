import { forkJoin, of } from 'rxjs'
import fromFetch from '../from-fetch'
import { catchError, switchMap } from 'rxjs/operators'
import i18n from '../../i18n'

const client = ({
  baseUrl = 'https://help.beyondrm.com/wp-json/wp/v2',
  body,
  headers = {},
  method = 'GET',
  path = ''
}) => {
  const reqOpts = {
    headers: {
      accept: 'application/json',
      ...headers
    },
    method
  }

  if (body && body.constructor === FormData) {
    reqOpts.body = body
  }

  if (body && body.constructor !== FormData) {
    reqOpts.headers['Content-Type'] = 'application/json'
    reqOpts.body = JSON.stringify(body)
  }

  const req = new Request(`${baseUrl}${path}`, reqOpts)

  return fromFetch(req).pipe(
    catchError(err => {
      console.log(req.url)
      if (req.url.includes('help')) {
        err.message = i18n.t('errors.help_not_available')
      }
      throw err
    }),
    switchMap(res => {
      if (res.status >= 300) {
        throw new Error(`${res.statusText} (${res.url})`)
      }

      return forkJoin([of(res.headers), res.json()])
    })
  )
}

export default client
