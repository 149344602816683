import { jwtDecode } from 'jwt-decode'
import { of, throwError } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { getApiHost } from '../../utils/site'
import fromFetch from '../from-fetch'

let store = null

const fetchToken = () => {
  const req = new Request(
    `https://${getApiHost()}/beeplugin/auth/`,
    {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${window.localStorage.loginToken}`
      }
    }
  )

  return fromFetch(req).pipe(
    switchMap(res =>
      res.ok
        ? res.json()
        : throwError(
          () =>
            new Error(
              `Error fetching token: ${res.statusText} (${res.status})`
            )
        )
    ),
    map(nextToken => (store = nextToken))
  )
}

export const create$ = () => {
  if (!window.localStorage.loginToken) {
    return throwError(() => new Error('loginToken not found!'))
  }

  if (store) {
    const { exp } = jwtDecode(store.token.access_token)

    if (new Date() < new Date(exp * 1000)) {
      return of(store)
    }
  }

  return fetchToken()
}

const adapter = { create$ }

export default adapter
