import { Deflate } from 'pako'
import { Observable } from 'rxjs'
import { map, toArray } from 'rxjs/operators'

const getFilename = (filename, gzip) => {
  if (!filename) return ''
  return `; filename="${filename}.json${gzip ? '.gzip' : ''}"`
}

const getMultipartHeaders = ({ boundary, filename, name, gzip }) => {
  const header =
    `--${boundary}\r\nContent-Disposition: form-data; name="${name}"${
      getFilename(filename, gzip)
    }\r\nContent-Type: application/json`

  if (gzip) {
    return `${header}+gzip\r\n\r\n`
  }

  return `${header}\r\n\r\n`
}

const mergeUint8ArraysToBuffer = arr => {
  const size = arr.reduce((a, b) => a + b.byteLength, 0)
  const result = new Uint8Array(size)

  let offset = 0

  for (let u8 of arr) {
    result.set(u8, offset)
    offset += u8.byteLength
  }

  return result.buffer
}

export const getGzipStream = () => {
  if (window.CompressionStream) {
    return new window.CompressionStream('gzip')
  }

  return new TransformStream({
    start(controller) {
      this.deflate = new Deflate({ gzip: true })

      this.deflate.onData = chunk => {
        controller.enqueue(chunk)
      }

      this.deflate.onEnd = () => {
        controller.terminate()
      }
    },

    transform(chunk) {
      this.deflate.push(chunk)
    },

    flush() {
      this.deflate.push(new Uint8Array(), true)
    }
  })
}

export const getLogStream = () =>
  new WritableStream({
    start() {
      console.log(`Starting LogStream`)
      this.chunks = []
    },

    write(chunk) {
      this.chunks.push(chunk)
    },

    close() {
      console.log('Closing LogStream', { chunks: this.chunks })
    },

    abort(reason) {
      console.error(`Aborting LogStream`, { chunks: this.chunks }, reason)
    }
  })

export const getMultipartStream = (boundary, extraParts = {}, gzip) =>
  new TransformStream(
    gzip
      ? {
        start(controller) {
          this.encoder = new TextEncoder()
          this.deflate = new Deflate({ gzip: true })

          this.deflate.onData = chunk => {
            controller.enqueue(chunk)
          }

          this.deflate.onEnd = () => {
            Object.entries(extraParts).forEach(([key, value]) => {
              const headers = getMultipartHeaders({ boundary, name: key })
              controller.enqueue(
                this.encoder.encode(`\r\n${headers}${JSON.stringify(value)}`)
              )
            })

            controller.enqueue(this.encoder.encode(`\r\n--${boundary}--`))
            controller.terminate()
          }

          const headers = getMultipartHeaders({
            boundary,
            filename: 'body',
            gzip: true,
            name: 'file'
          })

          controller.enqueue(this.encoder.encode(headers))
        },

        transform(chunk) {
          this.deflate.push(this.encoder.encode(chunk))
        },

        flush() {
          this.deflate.push(new Uint8Array(), true)
        }
      }
      : // non-gzip transformer
        {
          start(controller) {
            this.encoder = new TextEncoder()
            const headers = getMultipartHeaders({ boundary, name: 'file' })
            controller.enqueue(this.encoder.encode(headers))
          },

          transform(chunk, controller) {
            controller.enqueue(this.encoder.encode(chunk))
          },

          flush(controller) {
            Object.entries(extraParts).forEach(([key, value]) => {
              const headers = getMultipartHeaders({ boundary, name: key })
              controller.enqueue(
                this.encoder.encode(`\r\n${headers}${JSON.stringify(value)}`)
              )
            })

            controller.enqueue(this.encoder.encode(`\r\n--${boundary}--`))
          }
        }
  )

export const getStringifyStream = data =>
  new ReadableStream({
    start(controller) {
      this.buffer = ''

      this.enqueue = chunk => {
        this.buffer += chunk
        if (this.buffer.length > 32 * 1024) {
          controller.enqueue(this.buffer)
          this.buffer = ''
        }
      }

      this.stringify(data)

      if (this.buffer.length > 0) {
        controller.enqueue(this.buffer)
      }

      controller.close()
    },

    streamArray(arr) {
      this.enqueue('[')
      arr.forEach((el, i, { length }) => {
        this.stringify(el)
        if (i < length - 1) {
          this.enqueue(',')
        }
      })
      this.enqueue(']')
    },

    streamObject(obj) {
      this.enqueue('{')
      Object.entries(obj).filter(([, value]) => value !== undefined).forEach(
        ([key, value], i, { length }) => {
          this.enqueue(`${JSON.stringify(key)}:`)
          this.stringify(value)
          if (i < length - 1) {
            this.enqueue(',')
          }
        }
      )
      this.enqueue('}')
    },

    stringify(data) {
      if (data === null || data.constructor === Number) {
        this.enqueue(data)
      } else if (Array.isArray(data)) {
        this.streamArray(data)
      } else if (data.constructor === Object) {
        this.streamObject(data)
      } else {
        this.enqueue(JSON.stringify(data))
      }
    }
  })

export const getTextEncoderStream = () => {
  if (window.TextEncoderStream) {
    return new window.TextEncoderStream()
  }

  return new TransformStream({
    start() {
      this.encoder = new TextEncoder()
    },

    transform(chunk, controller) {
      controller.enqueue(this.encoder.encode(chunk))
    }
  })
}

export const readableToObservable = readable => {
  const reader = readable.getReader()

  return new Observable(async subscriber => {
    while (true) {
      const { done, value } = await reader.read()
      if (done) break
      subscriber.next(value)
    }
    subscriber.complete()
  }).pipe(toArray(), map(mergeUint8ArraysToBuffer))
}

export const readableToPromise = async readable => {
  let buffer = new Uint8Array()
  const reader = readable.getReader()

  while (true) {
    const { done, value } = await reader.read()
    if (done) break
    buffer = new Uint8Array([...buffer, ...value])
  }

  return buffer
}

export const supportsRequestStreams = () => {
  let duplexAccessed = false

  const hasContentType = new Request('', {
    body: new ReadableStream(),
    method: 'POST',
    get duplex() {
      duplexAccessed = true
      return 'half'
    }
  }).headers.has('Content-Type')

  return duplexAccessed && !hasContentType
}
