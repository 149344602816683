import { empty } from 'rxjs'
import { expand, forkJoin, map, reduce, scan, tap } from 'rxjs'
import { decrLoading, incrLoading } from '../../hooks/useUser'
import client from './client'

const getEndpoint = (endpoint, args = {}) => {
  for (const arg of Object.keys(args)) {
    endpoint = endpoint.replace(`{${arg}}`, args[arg])
  }

  if (endpoint.match(/{[\w|\d]+}/g) !== null) {
    throw new Error(`Not enough arguments provided for endpoint ${endpoint}`)
  }

  return endpoint
}

const adapter = (endpoint, customDefaults = {}) => {
  const defaults = {
    label: 'title.rendered',
    primaryKey: 'id',
    ...customDefaults
  }

  const find$ = (options = {}, args = {}) => {
    if (options.filters?.id?.length > 0) {
      return forkJoin(options.filters.id.map((id) => findOne$(id, args))).pipe(
        map((data) => ({
          data,
          limit: data.length,
          skip: 0,
          total: data.length
        })),
        tap({
          finalize: () => decrLoading(),
          subscribe: () => incrLoading()
        })
      )
    }

    const limit = options.limit ?? 10
    const skip = options.skip ?? 0
    const sort = options.sort || defaults.sort || { key: 'id', desc: true }

    const params = new URLSearchParams()
    params.set('per_page', limit)
    params.set('offset', skip)
    params.set('order', sort.desc ? 'desc' : 'asc')

    if (sort.key) {
      params.set('orderby', sort.key)
    }

    if (options.searchterm) {
      params.set('search', options.searchterm)
    }

    for (const [key, values] of Object.entries(options.filters || {})) {
      for (const value of values) {
        params.append(key, value)
      }
    }

    return client({
      baseUrl: defaults.baseUrl,
      path: `${getEndpoint(endpoint, args)}?${params.toString()}`
    }).pipe(
      map(([headers, data]) => ({
        data,
        limit,
        skip,
        sort,
        total: Number(headers.get('x-wp-total'))
      })),
      tap({
        finalize: () => decrLoading(),
        subscribe: () => incrLoading()
      })
    )
  }

  const findAll$ = ({ limit = 50, stream, ...options } = {}, ...args) => {
    const reducer = (acc, res) => {
      if (res.data) {
        acc = acc || { data: [] }
        return {
          ...res,
          data: [...acc.data, ...res.data],
          limit: res.total,
          skip: 0
        }
      }

      if (Array.isArray(res)) {
        acc = acc || []
        return [...acc, ...res]
      }

      throw new Error(`Unknown response signature: ${res}}`)
    }

    return find$({ limit, ...options }, ...args).pipe(
      expand(({ limit, skip, sort, total }) =>
        limit + skip < total
          ? find$({ ...options, limit, skip: skip + limit, sort }, ...args)
          : empty()
      ),
      stream ? scan(reducer) : reduce(reducer)
    )
  }

  const findOne$ = (id, args = {}, extraOpts = {}) => {
    return client({
      baseUrl: defaults.baseUrl,
      path: `${getEndpoint(endpoint, args)}/${id}`,
      ...extraOpts
    }).pipe(
      map(([, data]) => data),
      tap({
        finalize: () => decrLoading(),
        subscribe: () => incrLoading()
      })
    )
  }

  return {
    defaults,
    find$,
    findAll$,
    findOne$
  }
}

export default adapter
