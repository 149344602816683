import { useEffect } from 'react'

const cheats = {
  clippyclippy: () => {
    const audio = new Audio(
      'https://cdn.freesound.org/previews/554/554117_527080-lq.mp3'
    )
    let wrapper = window.document.getElementById('clippy')

    if (wrapper) {
      return window.document.body.removeChild(wrapper)
    }

    wrapper = window.document.createElement('div')
    wrapper.setAttribute('id', 'clippy')
    wrapper.style.bottom = '2rem'
    wrapper.style.cursor = 'pointer'
    wrapper.style.position = 'fixed'
    wrapper.style.right = '2rem'
    wrapper.style.zIndex = 6

    wrapper.onclick = () => {
      audio.play()
    }

    const img = window.document.createElement('img')
    img.setAttribute('src', '/clippy.gif')

    wrapper.appendChild(img)
    window.document.body.appendChild(wrapper)
  },
  earthquake: () =>
    import('../scss/cheats.scss').then(() =>
      window.document.body.classList.toggle('earthquake')
    ),
  ichbinjeck: () => {
    import('../scss/cheats.scss').then(() =>
      window.document.body.classList.toggle('jeck')
    )
    if (window.jeckHandler) {
      window.removeEventListener('click', window.jeckHandler)
      window.jeckHandler = undefined
      return
    }
    window.jeckHandler = () => {
      const audio = new Audio('/tata.m4a')
      try {
        audio.play()
      } catch {}
    }
    window.addEventListener('click', window.jeckHandler)
  },
  maxpain: () => {
    if (window.maxpainHandler) {
      window.removeEventListener('click', window.maxpainHandler)
      window.maxpainHandler = undefined
      return
    }
    window.maxpainHandler = () => {
      const audio = new Audio(
        'https://cdn.freesound.org/previews/220/220290_4102374-lq.mp3'
      )
      try {
        audio.play()
      } catch {}
    }
    window.addEventListener('click', window.maxpainHandler)
  },
  rollthedice: () =>
    import('../scss/cheats.scss').then(() =>
      window.document.body.classList.toggle('rollthedice')
    ),
  rickroll: () => {
    const iframe = window.document.createElement('iframe')
    iframe.setAttribute('allow', 'autoplay')
    iframe.setAttribute('height', '0')
    iframe.setAttribute('style', 'display:none')
    iframe.setAttribute('width', '0')
    iframe.src = 'https://www.youtube.com/embed/j1L3UFkNpIE?autoplay=1'
    window.document.body.appendChild(iframe)
  },
  upsidedown: () =>
    import('../scss/cheats.scss').then(() =>
      window.document.body.classList.toggle('upsidedown')
    )
}

const Cheats = () => {
  useEffect(() => {
    const encoder = new TextEncoder()

    const cheatState = Object.entries(cheats).map(([name, handler]) => ({
      cursor: 0,
      keyCodes: encoder.encode(name.toUpperCase()),
      handler
    }))

    const rootEl = document.querySelector('html')

    const handler = ({ keyCode }) => {
      cheatState.forEach(cheat => {
        if (keyCode === cheat.keyCodes[cheat.cursor]) {
          cheat.cursor++
        } else if (cheat.cursor > 0) {
          cheat.cursor = 0
        }

        if (cheat.cursor === cheat.keyCodes.byteLength) {
          cheat.cursor = 0
          const animation = rootEl.animate([
            { filter: 'none' },
            { filter: 'brightness(2) grayscale(1)' }
          ], { duration: 150, iterations: 1 })
          animation.finished.then(cheat.handler)
        }
      })
    }

    window.addEventListener('keydown', handler)
    return () => window.removeEventListener('keydown', handler)
  }, [])

  return null
}

export default Cheats
