import _set from 'lodash/set'
import { empty, forkJoin, of } from 'rxjs'
import { expand, map, reduce, scan, switchMap } from 'rxjs/operators'
import client from './client'
import { checkArgs } from '../../utils/args'

const adapter = (endpoint, customDefaults = {}) => {
  const defaults = { label: 'name', primaryKey: '_id', ...customDefaults }

  const find$ = (options = {}) => {
    const filters = options.filters || {} // { key: [value1, value2] }
    const limit = options.limit || 10
    const searchkeys = options.searchkeys || defaults.searchkeys || ['name']
    const searchterm = options.searchterm || ''
    const skip = options.skip || 0
    const sort = options.sort || { key: ``, desc: false }

    const body = { limit: 9999, selector: { endpoint } }

    Object.keys(filters).forEach(key =>
      filters[key].forEach(val => {
        body.selector[key] = val
      })
    )

    if (searchterm) {
      body.selector.$or = []
      searchkeys.forEach(searchkey => {
        body.selector.$or.push({
          [searchkey]: { $regex: `(?i)${searchterm}` }
        })
      })
    }

    if (sort?.key) {
      body.sort = [{ [sort.key]: sort.desc ? 'desc' : 'asc' }]
    }

    return client({
      body,
      ignoreLoadingState: options?.ignoreLoadingState,
      method: 'POST',
      path: '_find'
    }).pipe(
      map(({ docs }) => ({
        data: docs.slice(skip, skip + limit).map(({ endpoint, ...doc }) => doc),
        limit,
        skip,
        sort,
        total: docs.length
      }))
    )
  }

  const findAll$ = ({ stream, ...options } = {}, ...args) => {
    const limit = 100
    let skip = 0

    const reducer = (acc, res) => {
      if (res.data) {
        acc = acc || { data: [], total: 0 }
        return {
          ...res,
          data: [...acc.data, ...res.data],
          skip: 0,
          total: res.total + acc.data.length
        }
      }

      if (Array.isArray(res)) {
        acc = acc || []
        return [...acc, ...res]
      }

      throw new Error(`Unknown response signature: ${res}}`)
    }

    return find$({ ...options, limit, skip }, ...args).pipe(
      expand(({ data }) =>
        data.length === limit
          ? find$({ ...options, limit, skip: (skip += limit) }, ...args)
          : empty()
      ),
      stream ? scan(reducer) : reduce(reducer)
    )
  }

  const findOne$ = _id =>
    client({ path: _id }).pipe(map(({ endpoint, ...data }) => data))

  const create$ = data =>
    client({
      method: 'POST',
      body: {
        ...data,
        endpoint
      }
    }).pipe(switchMap(({ id }) => findOne$(id)))

  const patch$ = (_id, data) =>
    findOne$(_id).pipe(
      switchMap(doc =>
        client({
          body: {
            ...doc,
            ...data,
            endpoint
          },
          method: 'PUT',
          path: `${doc._id}?rev=${doc._rev}`
        })
      ),
      switchMap(({ id }) => findOne$(id))
    )

  const remove$ = (id, args, options = {}) => {
    checkArgs({ id })

    _set(options, 'filters._id', id.values ? [...id] : [id])

    return find$(options).pipe(
      switchMap(({ data }) =>
        forkJoin([
          of(data),
          client({
            body: {
              docs: data.map(({ _id, _rev }) => ({
                _id,
                _rev,
                _deleted: true
              }))
            },
            method: 'POST',
            path: '_bulk_docs'
          })
        ])
      ),
      map(([docs]) => (docs.length === 1 ? docs[0] : docs))
    )
  }

  return {
    create$,
    defaults,
    find$,
    findAll$,
    findOne$,
    patch$,
    remove$
  }
}

export default adapter
