import { forkJoin, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import api from '../index'
import client from './client'

// Globals

const store = {
  token: {
    access_token: null
  },
  uid: null,
  uidx: null
}

// Utils

const getComponents = (path) => path.split('/').filter((c) => c)

const getPath = (path, oneUp) => {
  if (path === '/' || path === '') {
    return ''
  }

  const components = getComponents(path)

  if (oneUp && path.length > 1) {
    components.pop()
  }

  return `/${components.map(encodeURIComponent).join('/')}`
}

// Helpers

const getAuth$ = () => {
  if (
    store.token['.expires'] &&
    new Date(store.token['.expires']) > new Date()
  ) {
    return of(store)
  }

  return api.beeauth.create$().pipe(
    switchMap(({ token, uid }) => {
      store.token = token
      store.uid = uid

      return client({
        baseUrl: 'https://bee-auth.getbee.io',
        body: { token_new: token.access_token, uid },
        ignoreLoadingState: true,
        method: 'POST',
        path: 'validator'
      })
    }),
    map(({ uidx }) => {
      store.uidx = uidx
      return store
    })
  )
}

const getDir$ = ({ auth: { token, uid }, options, path }) =>
  client({
    ...options,
    accessToken: token.access_token,
    headers: { 'x-bee-uid': uid },
    path: `cloudstorage${getPath(path)}/`
  })

const addDir$ = ({ auth: { token, uid }, path }) =>
  client({
    accessToken: token.access_token,
    headers: {
      'x-bee-filename': 'directory',
      'x-bee-fsp-directory': `${getPath(path)}/`,
      'x-bee-source': 'directory',
      'x-bee-uid': uid,
      'x-bee-username': 'NA'
    },
    method: 'POST',
    path: 'cloudstorage'
  })

const addFiles$ = ({ auth, files, options, path }) => {
  const source$ = options.deduplify
    ? getDir$({ auth, path })
    : of({ data: { items: [] } })

  return source$.pipe(
    switchMap(({ data: { items } }) =>
      forkJoin(
        files.map((file) => {
          const meta = items.find(
            (item) =>
              item.name === file.name &&
              Number(item.size) === file.size &&
              item['mime-type'] === file.type
          )

          if (meta) {
            return of({ data: { meta } })
          }

          const form = new FormData()
          form.append('file', file, file.name)

          return client({
            accessToken: auth.token.access_token,
            body: form,
            headers: {
              'x-bee-filename': file.name,
              'x-bee-fsp-directory': `${getPath(path)}/`,
              'x-bee-source': 'body',
              'x-bee-uid': auth.uid,
              'x-bee-username': 'NA'
            },
            method: 'POST',
            path: 'cloudstorage'
          })
        })
      )
    )
  )
}

// Methods

const create$ = (path = '/', files, options = {}) =>
  getAuth$().pipe(
    switchMap((auth) =>
      files
        ? addFiles$({ auth, files, options, path })
        : addDir$({ auth, path })
    )
  )

const find$ = (path = '/', options = {}) =>
  getAuth$().pipe(switchMap((auth) => getDir$({ auth, options, path })))

const remove$ = (files, options = {}) =>
  getAuth$().pipe(
    switchMap((auth) =>
      forkJoin(
        [...files].map(([path]) =>
          client({
            ...options,
            accessToken: auth.token.access_token,
            headers: {
              'x-bee-uid': auth.uid,
              'x-bee-username': 'NA'
            },
            method: 'DELETE',
            path: `cloudstorage${encodeURI(path)}`
          })
        )
      )
    )
  )

// Exports

const adapter = { create$, find$, remove$ }

export default adapter
