import {
  catchError,
  defer,
  EMPTY,
  finalize,
  forkJoin,
  from,
  of,
  switchMap
} from 'rxjs'
import { decrLoading, incrLoading } from '../../hooks/useUser'
import { getRandomNumber } from '../../utils/math'
import { getApiHost, getInstallType } from '../../utils/site'
import {
  getGzipStream,
  getMultipartStream,
  getStringifyStream,
  getTextEncoderStream,
  readableToObservable,
  supportsRequestStreams
} from '../../utils/streams'
import fromFetch from '../from-fetch'

const cachedMethods = ['GET', 'HEAD']
const cacheName = 'api-brm'

const getClientBoundary = () => {
  const customAlphabet = '1234567890abcdefghijklmnopqrstuvwxyz'
  let boundary = 'BRMAPIClientBoundary'

  for (let i = 0; i < customAlphabet.length; i++) {
    boundary += customAlphabet[getRandomNumber(0, customAlphabet.length - 1)]
  }

  return boundary
}

const getErrorMsg = (json) => {
  if (json.error) {
    return json.error
  }

  if (json.text && /<h1.*exception-message/.test(json.text)) {
    const match = json.text.match(/<h1.*?exception-message.*?>(.*)<\/h1>/is)

    if (match) {
      return match[1]
    }
  }
}

const getServicepath = (url) =>
  url
    .replace(/client\/\d+\/membertable\/\d+\//, '')
    .split('/')
    .map((part) => part.replace(/\?.*/, ''))
    .filter((part) => part && !/^emma$|^test$|^v\d+$|^\d.+$/.test(part))
    .pop()

const requiresInvalidation = (cachedReq, req) => {
  const serviceCached = getServicepath(cachedReq.url)
  const service = getServicepath(req.url)

  // These are url parts, not service names!

  if (service === 'campaign' && ['message', 'report'].includes(serviceCached)) {
    return true
  }

  if (
    service === 'dataupload' &&
    ['member', 'multiblacklist'].includes(serviceCached)
  ) {
    return true
  }

  if (service === 'domain:checkblocklist' && serviceCached === 'domain') {
    return true
  }

  if (service === 'mailclass' && serviceCached === 'campaign') {
    return true
  }

  if (
    service === 'message' &&
    ['campaign', 'render', 'report'].includes(serviceCached)
  ) {
    return true
  }

  if (service === 'placeholder' && serviceCached === 'render') {
    return true
  }

  if (service === 'subreport' && serviceCached === 'report') {
    return true
  }

  if (service === 'transactionalcampaign' && serviceCached === 'campaign') {
    return true
  }

  if (req.url.includes(`/${serviceCached}/`)) {
    return true
  }

  return service === serviceCached
}

export const invalidateCache = (urlPart) =>
  from(caches.open(cacheName)).pipe(
    switchMap((cache) => forkJoin([of(cache), cache.keys()])),
    switchMap(([cache, reqs]) => {
      const toDelete = reqs.filter((req) => req.url.includes(urlPart))
      return toDelete.length > 0
        ? forkJoin(toDelete.map((req) => cache.delete(req)))
        : EMPTY
    })
  )

const client = ({
  baseUrl = `https://${getApiHost()}`,
  blob,
  body: { _rev, ...body } = {},
  credentials,
  extraParts,
  gzip = true,
  ignoreCache,
  ignoreLoadingState,
  includeStatus,
  jsonapi,
  method = 'GET',
  multipart,
  path = '',
  transform
}) => {
  if (!ignoreLoadingState) {
    incrLoading()
  }

  const reqOpts = { headers: { Accept: 'application/json' }, method }

  // Headers

  if (credentials) {
    reqOpts.credentials = credentials
  }

  if (jsonapi) {
    reqOpts.headers.Accept = 'application/vnd.api+json'
  }

  if (localStorage.loginToken) {
    reqOpts.headers.Authorization = `Bearer ${localStorage.loginToken}`
  }

  // Body

  if (body && /^(PATCH|POST|PUT)$/.test(method)) {
    // Content-specific headers

    let boundary

    if (multipart) {
      boundary = getClientBoundary()
    }

    reqOpts.headers['Content-Type'] = multipart
      ? `multipart/form-data; boundary=${boundary}`
      : 'application/json'

    if (gzip && !multipart) {
      reqOpts.headers['Content-Encoding'] = 'gzip'
    }

    if (_rev) {
      reqOpts.headers['X-Revision'] = _rev
    }

    // Set up stream

    const stringifyStream = getStringifyStream(body)
    const textEncodeStream = getTextEncoderStream()

    reqOpts.body =
      body?.constructor === String
        ? body
        : multipart
          ? stringifyStream.pipeThrough(
              getMultipartStream(boundary, extraParts, gzip)
            )
          : gzip
            ? stringifyStream
                .pipeThrough(textEncodeStream)
                .pipeThrough(getGzipStream())
            : stringifyStream.pipeThrough(textEncodeStream)

    reqOpts.duplex = 'half'
  }

  // Logging

  if (getInstallType() === 'dev') {
    const opts = {
      ...reqOpts,
      _body: body
    }

    if (path.includes('?')) {
      const paths = path.split('?')
      const params = new URLSearchParams(paths[1])
      opts._params = [...params].reduce((acc, [key, value]) => {
        acc[key] = value
        return acc
      }, {})
    }

    console.debug(baseUrl + path, opts)
  }

  //  Start fetching

  const req = new Request(baseUrl + path, reqOpts)

  return defer(() =>
    // Check if response already exists in cache
    ignoreCache || window.Cypress
      ? of(false)
      : from(caches.match(req, { cacheName }))
  ).pipe(
    // Disable cache if match lookup threw an error (incognito mode etc.)
    catchError(() => {
      ignoreCache = true
      return of(false)
    }),
    switchMap((match) =>
      forkJoin([
        of(!!match),
        // Use cached response if valid match found, start fetching otherwise
        match && match.status < 500
          ? of(match)
          : !reqOpts.body || supportsRequestStreams()
            ? fromFetch(req)
            : readableToObservable(reqOpts.body).pipe(
                switchMap((body) =>
                  fromFetch(
                    new Request(baseUrl + path, {
                      ...reqOpts,
                      duplex: undefined,
                      body
                    })
                  )
                )
              )
      ])
    ),
    switchMap(async ([hasMatch, res]) => {
      if (!ignoreCache && !hasMatch && cachedMethods.includes(req.method)) {
        // store fresh response in cache
        const cache = await caches.open(cacheName)
        await cache.put(req, res.clone())
      }

      if (!ignoreCache && !cachedMethods.includes(req.method)) {
        // Remove related responses from cache if request was mutating resources
        const cache = await caches.open(cacheName)
        const cachedReqs = await cache.keys()
        await Promise.all(
          cachedReqs
            .filter((cachedReq) => requiresInvalidation(cachedReq, req))
            .map((cachedReq) => cache.delete(cachedReq))
        )
      }

      return res
    }),
    switchMap(async (res) => {
      // Gather status data
      const _http = {
        status: res.status,
        statusText: res.statusText,
        url: res.url
      }

      // Return binary data if requested
      if (blob && res.status < 299) {
        const binary = await res.blob()
        return { binary, _http }
      }

      // Return string by default
      const text = await res.text()
      return { text, _http }
    }),
    switchMap(async ({ binary, text, _http }) => {
      // Just return binary blob if requested
      if (binary) return binary

      // Parse string
      let json = {}
      try {
        if (text) {
          json = JSON.parse(text)
        }
      } catch {
        json.text = text
      }

      // clean up cache if there was a conflict (stale _rev)
      if (_http.status === 409) {
        const cache = await caches.open(cacheName)
        const cachedReqs = await cache.keys()
        cachedReqs
          .filter((cachedReq) =>
            requiresInvalidation(cachedReq, { url: _http.url })
          )
          .map((cachedReq) => cache.delete(cachedReq))
      }

      // Throw error on unexpected response
      if (_http.status >= 300) {
        const error = new Error(
          getErrorMsg(json) ||
            _http.statusText ||
            `HTTP status code ${_http.status}`
        )
        error.url = _http.url
        error.body = json
        throw error
      }

      // Transform response if requested
      const data = transform?.(json) || json

      // Include http status data in response if requested (ApiTester)
      return includeStatus ? { ...data, _http } : data
    }),
    finalize(() => !ignoreLoadingState && decrLoading())
  )
}

export default client
