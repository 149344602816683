import { jwtDecode } from 'jwt-decode'
import { of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import client from './client'

let interval

export const authenticate$ = () =>
  of(localStorage.getItem('loginToken')).pipe(
    switchMap(loginToken => {
      if (!loginToken) throw new Error('No token found!')

      const { exp } = jwtDecode(loginToken)
      const now = new Date().getTime() / 1000

      if (exp < now) {
        localStorage.removeItem('loginToken')
        throw new Error('Login token has expired!')
      }

      if (exp - now < 3600) login$().subscribe()

      return client({
        path: '/auth/token',
        transform: () => jwtDecode(loginToken)
      })
    })
  )

export const login$ = (username, password) =>
  client({
    body: username && password ? { username, password } : {},
    method: 'POST',
    path: '/auth/login',
    transform: ({ token }) => {
      localStorage.setItem('loginToken', token)
      if (!interval) {
        interval = setInterval(() => authenticate$().subscribe(), 3600000)
      }
      return jwtDecode(token)
    }
  })

export const logout$ = () => of(localStorage.removeItem('loginToken'))

export const salesview$ = id =>
  client({
    path: `/emma/report/${id}/salesview`
  })

const adapter = { authenticate$, login$, logout$, salesview$ }

export default adapter
