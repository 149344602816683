import _get from 'lodash/get'
import { getUserSettings } from '../hooks/useUser'

export const colors = {
  dark: {
    black: '#111',
    blue: 'var(--blue)',
    dark: 'var(--gray-dark)',
    darker: '#23282c',
    gray: 'var(--secondary)',
    grayer: 'var(--gray)',
    primary: 'var(--primary)',
    white: '#f5f5f5'
  }
}

export const styles = {
  dark: {
    boxShadow: `0 0 0 0.2rem ${colors.dark.dark}`
  }
}

export const getColor = (name) => {
  const userSettings = getUserSettings()
  return _get(colors, `${userSettings?.theme}.${name}`)
}


export const getStyle = (name) => {
  const userSettings = getUserSettings()
  return _get(styles, `${userSettings?.theme}.${name}`)
}
