import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

const numFlakes = 300

const getSnowFlake = (width, height) => ({
  o: 0.5 + Math.random() * 0.5,
  r: 1 + Math.random() * 2,
  vx: 0.5 - Math.random(),
  vy: 1 + Math.random() * 2.5,
  x: Math.random() * width,
  y: Math.random() * -height
})

let reqId
let snowflakes

const Snow = () => {
  const canvas = useRef()
  const ctx = useRef()
  const wrapper = useRef(document.createElement('div'))

  useEffect(() => {
    wrapper.current.classList.add(
      'bottom-0',
      'left-0',
      'pointer-events-none',
      'position-absolute',
      'right-0',
      'top-0'
    )

    wrapper.current.style.zIndex = 1050
    document.body.appendChild(wrapper.current)

    ctx.current = canvas.current.getContext('2d')

    snowflakes = new Array(300).fill(true).map(() =>
      getSnowFlake(wrapper.current.clientWidth, wrapper.current.clientHeight)
    )

    const handleResize = () => {
      canvas.current.height = wrapper.current.clientHeight
      canvas.current.width = wrapper.current.clientWidth
      ctx.current.fillStyle = '#FFF'
    }

    const handleUpdate = () => {
      ctx.current.clearRect(
        0,
        0,
        wrapper.current.clientWidth,
        wrapper.current.clientHeight
      )

      for (let i = 0; i < numFlakes; i++) {
        const snowflake = snowflakes[i]

        snowflake.y += snowflake.vy
        snowflake.x += snowflake.vx

        ctx.current.globalAlpha = snowflake.o
        ctx.current.beginPath()
        ctx.current.arc(
          snowflake.x,
          snowflake.y,
          snowflake.r,
          0,
          Math.PI * 2,
          false
        )
        ctx.current.closePath()
        ctx.current.fill()

        if (snowflake.y > wrapper.current.clientHeight) {
          snowflakes[i] = getSnowFlake(
            wrapper.current.clientWidth,
            wrapper.current.clientHeight
          )
        }
      }

      reqId = window.requestAnimationFrame(handleUpdate)
    }

    handleResize()

    reqId = window.requestAnimationFrame(handleUpdate)
    window.addEventListener('resize', handleResize, false)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.cancelAnimationFrame(reqId)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      document.body.removeChild(wrapper.current)
    }
  }, [])

  return createPortal(<canvas ref={canvas} />, wrapper.current)
}

export default Snow
