import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import FetchBackend from 'i18next-fetch-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import _set from 'lodash/set'
import _unset from 'lodash/unset'
import { initReactI18next } from 'react-i18next'
import { finalize, forkJoin, of, switchMap } from 'rxjs'
import api from './api'
import defaultLanguages from './data/languages.json'
import { getApiHost } from './utils/site'

const basePath = `https://${getApiHost()}`

export const clearI18nCache = () =>
  Object.keys(localStorage)
    .filter((key) => key.startsWith('i18next_res_'))
    .forEach((key) => localStorage.removeItem(key))

const updateTranslations$ =
  (iterator = () => {}) =>
  (tags = []) =>
    api.translations.findAll$().pipe(
      switchMap(({ data }) => {
        const locales = data.reduce(
          (acc, { language, localisation, label, optional }) => {
            acc[language] = { localisation, label, optional }
            return acc
          },
          {}
        )

        tags.forEach(iterator(locales))

        const languages = [...new Set(tags.map(([lang]) => lang))]

        return forkJoin(
          languages.map((lang) => api.translations.update$(lang, locales[lang]))
        ).pipe(
          switchMap(() => of(clearI18nCache())),
          finalize(i18n.reloadResources)
        )
      })
    )

export const languages = defaultLanguages

export const removeTranslations$ = updateTranslations$(
  (locales) =>
    ([lang, path]) =>
      _unset(locales, `${lang}.localisation.${path}`)
)

export const upsertTranslations$ = updateTranslations$(
  (locales) =>
    ([lang, path, translation]) =>
      _set(locales, `${lang}.localisation.${path}`, translation)
)

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    backend: {
      backends: [LocalStorageBackend, FetchBackend],
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000
        },
        {
          loadPath: `${basePath}/{{ns}}/{{lng}}`,
          parse: (str) => JSON.parse(str).localisation
        }
      ]
    },
    debug:
      typeof window !== 'undefined' && window.location.hostname === 'localhost',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    supportedLngs: languages.map((l) => l.lang)
  })

i18n.on(
  'languageChanged',
  (lang) =>
    typeof window !== 'undefined' &&
    document.documentElement.setAttribute('lang', lang)
)

export default i18n
