import React, { useEffect, useRef, useState } from 'react'
import useNotifications from '../hooks/useNotifications'
import Notification from './Notification'

const Notifications = () => {
  const { notifications } = useNotifications()
  const [overflow, setOverflow] = useState(false)
  const ref = useRef()
  const timer = useRef()

  useEffect(() => {
    timer.current = setTimeout(() => {
      requestAnimationFrame(() =>
        setOverflow(
          notifications.length > 0 &&
            ref.current?.clientHeight > window.innerHeight
        )
      )
    }, 250)

    return () => clearTimeout(timer.current)
  })

  return (
    <div
      className="d-flex flex-column m-2 position-fixed right-0 top-0"
      id="notifications"
      ref={ref}
      style={{ zIndex: 1051 }}
    >
      {[...notifications]
        .reverse()
        .map(({ message, ...notification }, i, { length }) => {
          const style = {}

          if (overflow && i === 0) {
            const shadows = ['1px -1px rgb(255 255 255 / 25%)']
            for (let j = i + 1; j < length; j++) {
              const n = j * 0.25
              const o = 100 - (j + 4) * 10
              if (o >= 10) {
                shadows.push(
                  `calc(${i === 0 ? 1 : 0}px + ${n}rem) calc(-${
                    i === 0 ? 1 : 0
                  }px + -${n}rem) var(--${
                    notifications[length - j - 1].type || 'secondary'
                  }-${o})`
                )
              }
            }

            style.boxShadow = [
              ...shadows,
              `0 ${length * 0.5}rem ${length * 1}rem rgb(0 0 0 / 15%)`
            ].join(', ')
          }

          if (overflow && i > 0) {
            style.visibility = 'hidden'
          }

          if (!overflow) {
            style.boxShadow = '0 0.5rem 1rem rgb(0 0 0 / 15%)'
          }

          return (
            <Notification
              body={message}
              key={notification.id}
              style={style}
              {...notification}
            />
          )
        })}
    </div>
  )
}

export default Notifications
