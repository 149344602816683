import i18n from './i18n'
import { addModals } from './hooks/useModals'
import { handleLogout$, restart } from './hooks/useUser'

let channel

const messageHandler = ({ data: { type } }) => {
  switch (type) {
    case 'LOGOUT':
      return handleLogout$().subscribe(() => {
        window.location.href = '/login'
      })

    case 'RESTART':
      return addModals([
        {
          actions: [
            { action: () => restart(false), label: i18n.t('errors.reload_app') }
          ],
          body: i18n.t('users.restart_warning'),
          noClose: true,
          title: i18n.t('users.usersettings')
        }
      ])

    default:
      throw new Error('Unknown channel message type', type)
  }
}

export const broadcastChannel = (msg) => {
  try {
    channel.postMessage(msg)
  } catch (error) {
    console.error(error)
  }
}

export const joinChannel = () => {
  channel = new BroadcastChannel('new-emma')
  channel.onmessage = messageHandler
}

export const leaveChannel = () => {
  try {
    channel.postMessage({ type: 'LOGOUT' })
    channel.close()
  } catch (error) {
    console.error(error)
  }
}
