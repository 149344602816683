import PropTypes from 'prop-types'
import React from 'react'
import { getStyles } from './styles'

const Option = ({
  children,
  data: { screenshot = {} },
  innerProps,
  innerRef,
  isDisabled,
  isFocused,
  isLoading,
  removeOption,
  value
}) => {
  if (Array.isArray(screenshot)) {
    screenshot = screenshot[0]
  }

  const { url_large, url_small } = screenshot

  return (
    <div
      ref={innerRef}
      style={getStyles({ isLoading }).option({}, { isDisabled, isFocused })}
      {...innerProps}
    >
      <div className='align-items-center d-flex justify-content-between'>
        <div className='d-flex'>
          {(url_large || url_small) && (
            <div className='position-relative' style={{ width: '3rem' }}>
              <div
                className='position-absolute align-items-center d-flex justify-content-center text-muted'
                style={{
                  height: '2.5rem',
                  margin: '-10px 0 0 -20px',
                  width: '3.5rem'
                }}
              >
                <i className='icon-picture' />
              </div>
              <div
                className='position-absolute'
                style={{
                  backgroundImage: `url(${url_large || url_small})`,
                  backgroundPosition: 'top center',
                  backgroundSize: '250%',
                  height: '2.5rem',
                  left: 0,
                  margin: '-10px 0 0 -20px',
                  top: 0,
                  width: '3.5rem'
                }}
              />
            </div>
          )}

          <div>{children}</div>
        </div>

        {removeOption && (
          <button
            className='border-0 btn btn-outline-danger btn-sm px-1 py-0'
            onClick={e => {
              e.stopPropagation()
              removeOption(value)
            }}
            type='button'
          >
            <i className='icon-trash' />
          </button>
        )}
      </div>
    </div>
  )
}

Option.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    screenshot: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  }),
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.func,
  isDisabled: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  removeOption: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string
  ])
}

export default Option
