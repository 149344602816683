import { of } from 'rxjs'
import { finalize, switchMap } from 'rxjs/operators'
import { decrLoading, incrLoading } from '../../hooks/useUser'
import fromFetch from '../from-fetch'

const client = ({
  accessToken,
  baseUrl = 'https://bee-cloudstorage.getbee.io/cs',
  body,
  headers = {},
  ignoreLoadingState,
  method = 'GET',
  path = '',
  transform
}) => {
  headers = Object.fromEntries(
    Object.entries(headers).map(([key, value]) => [
      key,
      value.replace(/[\u0100-\uFFFF]/g, '_')
    ])
  )

  const reqOpts = {
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
      ...headers
    },
    method
  }

  if (body && body.constructor === FormData) {
    reqOpts.body = body
  }

  if (body && body.constructor !== FormData) {
    reqOpts.headers['Content-Type'] = 'application/json'
    reqOpts.body = JSON.stringify(body)
  }

  if (!ignoreLoadingState) {
    incrLoading()
  }

  if (path.endsWith('//')) {
    path = path.substring(0, path.length - 1)
  }

  const req = new Request(`${baseUrl}/${path}`, reqOpts)

  return fromFetch(req).pipe(
    switchMap((res) => {
      if (res.status >= 300) {
        throw new Error(`${res.statusText} (${res.url})`)
      }
      return res.json()
    }),
    switchMap((json) => {
      if (json.code && json.message) {
        throw new Error(`${json.message} (${json.code})`)
      }
      return of(transform ? transform(json) : json)
    }),
    finalize(() => {
      if (!ignoreLoadingState) {
        decrLoading()
      }
    })
  )
}

export default client
