import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { decrLoading, incrLoading } from '../hooks/useUser'

const Loading = ({ animate = true, setCounter, text, ...props }) => {
  const { t } = useTranslation()
  const str = (text || t('misc.loading')).split('')

  useEffect(() => {
    if (setCounter) {
      incrLoading()
      return () => decrLoading()
    }
  }, [setCounter])

  return (
    <span role="alert" aria-live="assertive" {...props}>
      {str.map((s, i) => (
        <span
          className={animate ? 'animation-fade-out-in' : undefined}
          key={`${i}-${s}`}
          style={{ animationDelay: `${(i + 1) / str.length}s` }}
        >
          {s}
        </span>
      ))}
    </span>
  )
}

Loading.propTypes = {
  animate: PropTypes.bool,
  setCounter: PropTypes.bool,
  text: PropTypes.string
}

export default Loading
