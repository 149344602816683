import { finalize, forkJoin, switchMap } from 'rxjs'
import { decrLoading, incrLoading } from '../hooks/useUser'
import fromFetch from './from-fetch'

export const baseUrl = 'https://emma-downloads.beyondrm.com'

const getEndpoint = (endpoint, args = {}) => {
  Object.keys(args).forEach((arg) => {
    endpoint = endpoint.replace(`{${arg}}`, args[arg])
  })

  if (endpoint.match(/{[\w|\d]+}/g) !== null) {
    throw new Error(`Not enough arguments provided for endpoint ${endpoint}`)
  }

  return endpoint
}

const getHeaders = (hasBody, accept = 'application/json') => {
  const headers = {
    'accept-encoding': 'br, gzip, deflate',
    accept
  }

  if (hasBody) {
    headers['content-type'] = 'application/json'
  }

  if (localStorage.loginToken) {
    headers.authorization = `Bearer ${localStorage.loginToken}`
  }

  return headers
}

const handleResponseBlob = async (res) => {
  const blob = await res.blob()

  if (!res.ok) throw new Error('Error retrieving file')

  const [, filename] =
    res.headers.get('content-disposition')?.match(/filename="(.*?)"/) || []

  blob.filename = filename
  return blob
}

const handleResponseJson = async (res) => {
  const body = await res.json()
  if (!res.ok) throw new Error(body.error || 'Unknown error')
  return body
}

const adapter = (endpoint = '/', customDefaults = {}) => ({
  defaults: { label: 'slug', primaryKey: 'id', ...customDefaults },

  create$: (data = {}, options = {}, args) => {
    !options.ignoreLoadingState && incrLoading()

    const path = getEndpoint(endpoint, args)

    const req = new Request(`${baseUrl}${path}`, {
      body: JSON.stringify(data),
      headers: getHeaders(true),
      method: 'POST'
    })

    return fromFetch(req).pipe(
      switchMap(handleResponseJson),
      finalize(() => !options.ignoreLoadingState && decrLoading())
    )
  },

  find$: (options = {}, args) => {
    !options.ignoreLoadingState && incrLoading()

    const params = new URLSearchParams()
    const path = getEndpoint(endpoint, args)

    options.filters &&
      Object.entries(options.filters).forEach(([key, values]) =>
        values.forEach((value) => params.append(key, value))
      )

    options.limit && params.set('limit', options.limit)
    options.recurring_only === true && params.set('recurring_only', true)
    options.skip && params.set('offset', options.skip)
    options.searchterm && params.set('search', options.searchterm)
    options.sort?.key && params.set('sortkey', options.sort.key)
    options.sort?.desc && params.set('sortdesc', 'true')

    const req = new Request(`${baseUrl}${path}?${params.toString()}`, {
      headers: getHeaders()
    })

    return fromFetch(req).pipe(
      switchMap(handleResponseJson),
      finalize(() => !options.ignoreLoadingState && decrLoading())
    )
  },

  findOne$: (id = '', options = {}, args) => {
    !options.ignoreLoadingState && incrLoading()

    const path = getEndpoint(endpoint, args)

    const req = new Request(`${baseUrl}${path}${id}`, {
      headers: getHeaders()
    })

    let handler = handleResponseJson

    if (options.blob) {
      handler = handleResponseBlob
    }

    if (options.raw) {
      handler = (res) => Promise.resolve(res)
    }

    return fromFetch(req).pipe(
      switchMap(handler),
      finalize(() => !options.ignoreLoadingState && decrLoading())
    )
  },

  patch$: (id, data = {}, options = {}, args) => {
    !options.ignoreLoadingState && incrLoading()

    const path = getEndpoint(endpoint, args)

    const req = new Request(`${baseUrl}${path}${id}`, {
      body: JSON.stringify(data),
      headers: getHeaders(true),
      method: 'PATCH'
    })

    return fromFetch(req).pipe(
      switchMap(handleResponseJson),
      finalize(() => !options.ignoreLoadingState && decrLoading())
    )
  },

  remove$: (id = '', options = {}, args) => {
    !options.ignoreLoadingState && incrLoading()

    const path = getEndpoint(endpoint, args)

    const ids = id.values ? [...id] : [id]

    return forkJoin(
      ids.map((id) => {
        const req = new Request(`${baseUrl}${path}${id}`, {
          headers: getHeaders(),
          method: 'DELETE'
        })

        return fromFetch(req).pipe(switchMap(handleResponseJson))
      })
    ).pipe(finalize(() => !options.ignoreLoadingState && decrLoading()))
  }
})

export default adapter
