import fromFetch from '../from-fetch'
import { finalize, map, switchMap } from 'rxjs/operators'
import { decrLoading, incrLoading } from '../../hooks/useUser'

const client = ({
  baseUrl = 'https://api.mundpropaganda.net/brm',
  body,
  ignoreLoadingState,
  method = 'GET',
  path = '',
  transform
}) => {
  const reqOpts = { headers: {}, method }

  reqOpts.headers.Accept = 'application/json'
  reqOpts.headers['Content-Type'] = 'application/json'
  reqOpts.headers.Authorization =
    'Basic YnJtOkNEREExVEJTcG8yZ05ueW1WNlZtTGJRTnA='

  if (body && method.match(/PATCH|POST|PUT/)) {
    reqOpts.body = JSON.stringify(body)
  }

  !ignoreLoadingState && incrLoading()

  const req = new Request(`${baseUrl}/${path}`, reqOpts)

  return fromFetch(req).pipe(
    switchMap((res) => {
      if (res.status >= 300) {
        throw new Error(`${res.statusText} (${res.url})`)
      }
      return res.json()
    }),
    map((json) => transform?.(json) || json),
    finalize(() => !ignoreLoadingState && decrLoading())
  )
}

export default client
